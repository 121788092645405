<template>
    <!--新增对话框-->
    <el-dialog
            :title="editForm.acquisitionTaskId?'编辑配置':'新增配置'"
            :visible.sync="dialogVisible"
            width="1500px"
            :close-on-click-modal="false"
            :before-close="handleClose">

        <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm" inline="inline">
            <el-row>
                <el-form-item label="采集地址：" prop="acquisitionTaskUrl" label-width="150px">
                    <el-input v-model="editForm.acquisitionTaskUrl" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
                <el-form-item label="域名：" prop="domain" label-width="150px">
                    <el-input v-model="editForm.domain" autocomplete="off" style="width: 530px"></el-input>
                </el-form-item>
                <el-form-item label="采集类型：" label-width="150px" prop="isList">
                    <el-select v-model="editForm.isList" clearable placeholder="选择采集类型" style="width: 185px">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="图片是否下载：" label-width="150px" prop="isDownload">
                    <el-select v-model="editForm.isDownload" clearable placeholder="选择是否下载" style="width: 185px">
                        <el-option
                                v-for="item in options1"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="编码格式：" prop="encodingFormat" label-width="150px">
                    <el-input v-model="editForm.encodingFormat" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="列表坐标：" prop="nodes" label-width="150px">
                    <el-input v-model="editForm.nodes" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>

                <el-form-item label="下一页格式：" prop="nextPageUrl" label-width="150px">
                    <el-input v-model="editForm.nextPageUrl" autocomplete="off" style="width: 530px"></el-input>
                </el-form-item>
                <el-form-item label="总页码：" prop="twoNextPageUrl" label-width="150px">
                    <el-input v-model="editForm.twoNextPageUrl" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
                <el-form-item label="页标题坐标：" prop="pageTitle" label-width="150px">
                    <el-input v-model="editForm.pageTitle" autocomplete="off" style="width: 530px"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="详情内容坐标：" prop="publishingContent" label-width="150px">
                    <el-input v-model="editForm.publishingContent" autocomplete="off" style="width: 1230px"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="详情附件坐标：" prop="psageEnclosure" label-width="150px">
                    <el-input v-model="editForm.pageEnclosure" autocomplete="off" style="width: 1230px"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="发布时间坐标：" prop="pageTime" label-width="150px">
                    <el-input v-model="editForm.pageTime" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
                <el-form-item label="时间格式:" prop="timeFormat" label-width="150px">
                    <el-select v-model="editForm.timeFormat" clearable placeholder="选择时间格式" style="width: 530px">
                        <el-option
                                v-for="item in timeFormatList"
                                :key="item.id"
                                :label="item.timeformat"
                                :value="item.timeformat">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间左边标识:" prop="timeDescriptionLeft" label-width="150px">
                    <el-input v-model="editForm.timeDescriptionLeft" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
                <el-form-item label="时间右边标识：" prop="timeDescriptionRight" label-width="150px">
                    <el-input v-model="editForm.timeDescriptionRight" autocomplete="off" style="width: 530px"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="作者坐标：" prop="pageAuthor" label-width="150px">
                    <el-input v-model="editForm.pageAuthor" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
                <el-form-item label="作者左边标识:" prop="authorDescriptionLeft" label-width="150px">
                    <el-input v-model="editForm.authorDescriptionLeft" autocomplete="off" style="width: 530px"></el-input>
                </el-form-item>
                <el-form-item label="作者右边标识：" prop="authorDescriptionRight" label-width="150px">
                    <el-input v-model="editForm.authorDescriptionRight" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="发布者坐标：" prop="pagePublisher" label-width="150px">
                    <el-input v-model="editForm.pagePublisher" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
                <el-form-item label="发布者左边标识:" prop="publisherDescriptionLeft" label-width="150px">
                    <el-input v-model="editForm.publisherDescriptionLeft" autocomplete="off" style="width: 530px"></el-input>
                </el-form-item>
                <el-form-item label="发布者右边标识：" prop="publisherDescriptionRight" label-width="150px">
                    <el-input v-model="editForm.publisherDescriptionRight" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="浏览量坐标：" prop="pageViews" label-width="150px">
                    <el-input v-model="editForm.pageViews" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
                <el-form-item label="浏览量左边标识:" prop="viewsDescriptionLeft" label-width="150px">
                    <el-input v-model="editForm.viewsDescriptionLeft" autocomplete="off" style="width: 530px"></el-input>
                </el-form-item>
                <el-form-item label="浏览量右边标识：" prop="viewsDescriptionRight" label-width="150px">
                    <el-input v-model="editForm.viewsDescriptionRight" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item label="文章来源坐标：" prop="pageArticleSource" label-width="150px">
                    <el-input v-model="editForm.pageArticleSource" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
                <el-form-item label="文章来源左边标识:" prop="articleSourceDescriptionLeft" label-width="150px">
                    <el-input v-model="editForm.articleSourceDescriptionLeft" autocomplete="off" style="width: 530px"></el-input>
                </el-form-item>
                <el-form-item label="文章来源右边标识：" prop="articleSourceDescriptionRight" label-width="150px">
                    <el-input v-model="editForm.articleSourceDescriptionRight" autocomplete="off" style="width: 550px"></el-input>
                </el-form-item>
            </el-row>
            <el-row type="flex" justify="center">
                <el-form-item align="center">
                    <el-button type="primary" @click="submitForm('editForm')">保存</el-button>
                    <el-button @click="resetForm('editForm')">重置</el-button>
                </el-form-item>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        name: "AddOrUpdateAcquisitiontask",
        components:{

        },
        data(){
            return{
                options: [{
                    value: 1,
                    label: '列表'
                }, {
                    value: 2,
                    label: '详情'
                }],
                options1: [{
                    value: 1,
                    label: '下载'
                }, {
                    value: 2,
                    label: '不下载'
                }],
                timeFormatList:[],
                editForm:{},
                dialogVisible:false,
                editFormRules: {
                    title: [
                        {required: true, message: '请输入标题', trigger: 'blur'}
                    ],
                    imgCover: [
                        {required: true, message: '请上传封面', trigger: 'blur'}
                    ],
                },
            }
        },
        methods:{
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
                this.editForm = {}
                this.timeFormatList=[]
            },
            handleClose() {
                this.resetForm('editForm')
            },
            getTag(ids,name){
                this.editForm.relTagIdList=ids
                this.editForm.relTagName= name
                this.$forceUpdate();
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios.post('/admin/acquisitiontask/' + (this.editForm.acquisitionTaskId?'updateAcquisitionTas' : 'addAcquisitionTas'), this.editForm)
                            .then(res => {

                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    onClose:() => {
                                        this.$emit('refreshDataList')
                                    }
                                });
                                this.dialogVisible = false
                                this.resetForm(formName)
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            init(id){
                this.dialogVisible=true;
                if (id!=null&&id!=''&&id>0){
                    this.$axios.post('/admin/acquisitiontask/getAcquisitionTasInfo' ,{acquisitionTaskId:id}).then(res => {
                        this.editForm = res.data
                    })
                }else {
                    this.editForm.acquisitionType=1;
                }
                this.$axios.post('/admin/acquisitiontask/getTimeformatList' ).then(res => {
                    this.timeFormatList = res.data
                })
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
